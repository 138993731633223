<div style="margin-top: 20px">
    <p-toast />
    <div class="card flex">
        <p-button (onClick)="showDialog()" icon="pi pi-plus" label="Ajouter une diapositive" />
        <p-dialog header="Edit Profile" [modal]="true" [(visible)]="display" [style]="{ width: '25rem' }">
            <span class="p-text-secondary block mb-5">Ajouter une diapositive</span>
            <div class="flex align-items-center gap-3 mb-3">
                <label for="title" class="font-semibold w-6rem">Titre</label>
                <input pInputText id="title" class="flex-auto" autocomplete="off" name="title" [(ngModel)]="title" />
            </div>
            <div class="flex align-items-center gap-3 mb-5">
                <label for="description" class="font-semibold w-6rem">Description</label>
                <textarea pInputTextarea id="description" class="flex-auto" autocomplete="off" rows="5" name="description" [(ngModel)]="description"></textarea>
            </div>
            <div class="flex align-items-center gap-3 mb-5">
                <label for="image" class="font-semibold w-6rem">Image</label>
                <p-fileUpload
                    #fileUpload
                    id="image"
                    name="image"
                    mode="basic" 
                    chooseLabel="Choisir" 
                    chooseIcon="pi pi-upload"
                    customUpload="true"
                    accept="image/*"
                    maxFileSize="1000000"
                    (onSelect)="onSelect($event)" />
            </div>
            <div class="flex justify-content-end gap-2">
                <p-button label="Annuler" severity="secondary" (onClick)="display = false" />
                <p-button label="Enregistrer" (onClick)="onSubmit()" />
            </div>
        </p-dialog>
    </div>
  <p-table [value]="slides.results" [paginator]="false" [rows]="slides.limit" [totalRecords]="slides.total" (onLazyLoad)="loadSlides($event)">
    <ng-template pTemplate="header">
      <tr>
        <th>ID</th>
        <th>Title</th>
        <th>Description</th>
        <th>Image</th>
        <th>Active</th>
        <th>Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-slide>
      <tr>
        <td>{{ slide.id }}</td>
        <td>{{ slide.title }}</td>
        <td>{{ slide.description }}</td>
        <td><p-image [src]="slide.image.url" [preview]="true" alt="slide" width="100" height="100"/></td>
        <td><p-tag [value]="slide.active ? 'Oui' : 'Non'" [severity]="slide.active ? 'success' : 'danger'"></p-tag></td>
        <td>
          <p-confirmDialog />
          <p-button icon="pi pi-trash" [outlined]="true" (onClick)="confirmDelete($event, slide.id)" severity="danger" />
        </td>
      </tr>
    </ng-template>
    
    <ng-template pTemplate="emptymessage">
        <tr >
            <td colspan="5" class="text-center">Aucune diapositive trouvée</td>
        </tr>
    </ng-template>
    
    
  </p-table>
  <div class="flex-auto">
    <p-paginator 
        (onPageChange)="onPageChange($event)" 
        [first]="first" 
        [rows]="limit" 
        [totalRecords]="slides.total" 
        [rowsPerPageOptions]="[5, 10, 20, 30]"
         />
  </div>
</div>