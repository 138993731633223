import { Component } from '@angular/core';

@Component({
  selector: 'app-ticketing',
  standalone: true,
  imports: [],
  templateUrl: './ticketing.component.html',
  styleUrl: './ticketing.component.css'
})
export class TicketingComponent {

}
