import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private apiUrl = `${environment.apiUrl}/events`;
  constructor(private http: HttpClient) { }

  getEvents(page: number = 1, limit: number = 5, eventType:string): Observable<any> {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found');
    }

    return this.http.get(`${this.apiUrl}/all/admin?page=${page}&limit=${limit}&eventType=${eventType}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  updateEvent(id: string, data: any): Observable<any> {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found');
    }
    return this.http.put(`${this.apiUrl}/update/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
