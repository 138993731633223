import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { UserService } from '../services/user.service';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { SelectOption } from '../models/select.option.interface';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { User } from '../models/user.interface';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    PaginatorModule, 
    TableModule, 
    ButtonModule, 
    DropdownModule,
    TagModule,
    CommonModule,
    FormsModule,
    ToastModule,
    DialogModule,
    FileUploadModule,
    ReactiveFormsModule,
    ConfirmDialogModule
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css',
  providers: [MessageService, ConfirmationService]
})
export class UsersComponent implements OnInit {

  users: any[] = [];
  totalRecords: number = 0;
  currentPage: number = 1;
  first = 0;
  rows = 5;
  display: boolean = false;
  displayEdit: boolean = false;
  role: SelectOption = { label: 'All', value: 'all' };
  status: SelectOption = { label: 'All', value: 'all' };

  statuses: SelectOption[] = [
    { label: 'Tous', value: 'all' },
    { label: 'Actif', value: 'true' },
    { label: 'Inactif', value: 'false' },
  ];
  roles: SelectOption[] = [
    { label: 'Tous', value: 'all' },
    { label: 'Particulier', value: 'particular' },
    { label: 'Utilisateur', value: 'user' },
    { label: 'Administrateur', value: 'admin' },
  ];
  userForm: any;
  userEditForm: any;
  selectedUser: User | null = null;

  constructor(
    private userService: UserService, 
    private messageService: MessageService, 
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.userForm = new FormGroup({
      lastName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required)
    });
    this.userEditForm = new FormGroup({
      lastName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required)
    });
    this.loadUsers();
  }

  loadUsers(event?: any) {
    this.userService.getUsers(this.currentPage, this.rows, this.role.value, this.status.value).subscribe((data) => {
      this.users = data.results;
      this.totalRecords = data.total;
      this.currentPage = data.page;
    });
  }

  getSeverity(status: string): any {
    switch (status) {
        case 'particular':
            return 'success';
        case 'user':
            return 'warning';
        case 'admin':
            return 'danger';
        default:
            return 'info';
    }
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    this.currentPage = event.page + 1;
    this.loadUsers();
  }

  onRoleChange(event: any) {
    this.role = event;
    this.loadUsers();
  }

  onStatusChange(event: any) {
    this.status = event;
    this.loadUsers();
  }

  showDialog() {
    this.display = true;
  }

  onSubmitUserCreation() {
    const user: User = {
      lastName: this.userForm.value.lastName,
      firstName: this.userForm.value.firstName,
      email: this.userForm.value.email,
      phoneNumber: this.userForm.value.phoneNumber.toString(),
      role: this.userForm.value.role.value
    }
    this.userService.createUser(user).subscribe({
      next: (data) => {
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Utilisateur créé avec succès'});
        this.loadUsers();
        this.userForm.reset();
        this.display = false;
      },
      error: (error) => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: error.error.message});
      }
    });
  }

  showEditDialog(user: User) {
    console.log(user.role);
    this.userEditForm = new FormGroup({
      lastName: new FormControl(user.lastName, Validators.required),
      firstName: new FormControl(user.firstName, Validators.required),
      email: new FormControl(user.email, Validators.required),
      phoneNumber: new FormControl(user.phoneNumber, Validators.required),
      role: new FormControl(this.roles.find(r => r.value === user.role), Validators.required)
    });
    this.selectedUser = user;
    this.displayEdit = true;
  }

  onSubmitUserEdit() {
    const user: User = {
      lastName: this.userEditForm.value.lastName,
      firstName: this.userEditForm.value.firstName,
      email: this.userEditForm.value.email,
      phoneNumber: this.userEditForm.value.phoneNumber.toString(),
      role: this.userEditForm.value.role.value
    }
    if (this.selectedUser && this.selectedUser.id) {
    this.userService.updateUser(this.selectedUser.id, user).subscribe({
      next: (data) => {
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Utilisateur modifié avec succès'});
        this.loadUsers();
        this.userEditForm.reset();
        this.displayEdit = false;
        this.selectedUser = null;
      },
      error: (error) => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: error.error.message});
      }
      });
    }
  }

  deleteUser(id: string) {
    this.userService.deleteUser(id).subscribe({
      next: (data) => {
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Utilisateur supprimé avec succès'});
        this.loadUsers();
      },
      error: (error) => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: error.error.message});
      }
    });
  }

  confirmDelete(event: Event, id: string) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Voulez-vous supprimer cet utilisateur?',
        header: 'Confirmation de suppression',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger p-button-text",
        rejectButtonStyleClass:"p-button-text p-button-text",
        acceptIcon:"pi pi-trash",
        rejectIcon:"pi pi-times",
  
        accept: () => {
            this.messageService.add({ severity: 'info', summary: 'Supprimé', detail: 'Utilisateur supprimé avec succès' });
            this.deleteUser(id);
        },
        reject: () => {
            this.messageService.add({ severity: 'error', summary: 'Rejeté', detail: 'Vous avez rejeté la suppression' });
        }
    });
  }
}
