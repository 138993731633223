
  <div class="layout-wrapper">
    <div class="layout-topbar">
      <div class="layout-topbar-content">
        <div class="layout-topbar-left-logo">
          <img src="assets/img/logo.png" width="60" height="60" alt="Logo">

          <i class="pi pi-bars layout-topbar-button" (click)="visibleSidebar = !visibleSidebar"></i>
        </div>
        <div class="">
          <button class="layout-topbar-button" pButton icon="pi pi-sign-out" (click)="logout()"></button>
        </div>
      </div>


    </div>
    
    
    <div class="layout-main-container">
      <div  [style]="{'visibility': visibleSidebar ? 'visible' : 'hidden', 'width': visibleSidebar ? '300px' : '0px'}">
        <div class="layout-sidebar">
          <ul class="layout-menu">
              <li  *ngFor="let item of items" class="">
                <a routerLink="{{item.routerLink}}" routerLinkActive="active-route">
                <i [class]="item.icon" class="layout-menuitem-icon"></i>
				        <span class="layout-menuitem-text">{{item.label}}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="layout-main" [style]="{'width': visibleSidebar ? 'calc(100% - 300px)' : '100%'}">
        <router-outlet></router-outlet>
      </div>

    </div>
  </div>

  
  
  
 
  
  