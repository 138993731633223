
  <!-- <p-sidebar [(visible)]="visibleSidebar" position="left">
    <h1 style="color: blue;">Sidebar Content</h1>
    <button  label="Close Sidebar" (click)="visibleSidebar = false"></button>
  </p-sidebar>
  
  <button  label="Show Sidebar" (click)="visibleSidebar = true"></button>

  <p>payments works!</p>
  <p>payments works!</p>
   -->

   <!-- <p-sidebar [(visible)]="visibleSidebar" position="left">
    <h3>Menu</h3>
    <ul>
      <li><a routerLink="/dashboard">Dashboard</a></li>
      <li><a routerLink="/users">Users</a></li>
      <li><a routerLink="/payments">Payments</a></li>
    </ul>
  </p-sidebar>
  
  <button pButton label="Toggle Sidebar" (click)="visibleSidebar = !visibleSidebar"></button>
   -->
  <!-- Main content area -->
  <!-- <router-outlet></router-outlet> -->
  
  <!-- <p-sidebar [(visible)]="visibleSidebar" position="left" styleClass="ui-sidebar-sm">
    <h3>Menu</h3>
    <ul class="p-menu-list">
      <li><a routerLink="/dashboard" routerLinkActive="active">Dashboard</a></li>
      <li><a routerLink="/users" routerLinkActive="active">Users</a></li>
      <li><a routerLink="/payments" routerLinkActive="active">Payments</a></li>
      <li><button pButton type="button" label="Logout" icon="pi pi-sign-out" (click)="logout()"></button></li>
    </ul>
  </p-sidebar>
  
  <button pButton label="Toggle Sidebar" (click)="visibleSidebar = !visibleSidebar"></button>
  <router-outlet></router-outlet> -->

  <div class="">
   
      <h1 class="text-2xl font-bold text-red">Dashboard</h1>
   
  </div>