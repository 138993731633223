import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { SlidesService } from '../services/slides.service';
import { ListCommonInterface } from '../models/list.common.interface';
import { SlidesInterface } from '../models/slides.interface';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUpload, FileUploadModule, UploadEvent } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ImageInterface } from '../models/image.interface';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TagModule } from 'primeng/tag';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-slides',
  standalone: true,
  imports: [
    TableModule, 
    CommonModule, 
    PaginatorModule, 
    DialogModule, 
    ButtonModule, 
    InputTextModule, 
    InputTextareaModule,
    FileUploadModule,
    ToastModule,
    FormsModule,
    ConfirmDialogModule,
    TagModule,
    ImageModule
  ],
  templateUrl: './slides.component.html',
  styleUrl: './slides.component.css',
  providers: [MessageService, ConfirmationService]
})
export class SlidesComponent implements OnInit {
  slides: ListCommonInterface<SlidesInterface> = {
    results: [],
    total: 0,
    page: 1,
    limit: 10
  };
  currentPage: number = 1; // To manage pagination
  limit: number = 10; // Default number of records per page
  first = 0;
  totalRecords: number = 0;
  display: boolean = false;
  title: string = '';
  description: string = '';

  @ViewChild('fileUpload') fileUpload!: FileUpload;

  constructor(
    private slidesService: SlidesService, 
    private messageService: MessageService, 
    private confirmationService: ConfirmationService
  ) {
    
  }



  ngOnInit() {
    this.loadSlides();
  }

  loadSlides(event?: any) {
    this.slidesService.getSlides(this.currentPage, this.limit).subscribe((slides) => {
      this.slides = slides;
    });
  }

  onPageChange(event: any) {
    console.log("event", event)
    this.first = event.first;
    this.limit = event.rows;
    this.currentPage = event.page + 1;
    this.loadSlides();
}

showDialog() {
  this.display = true;
}

onSelect(event: any) {
  // console.log("event", event)
  this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode' });
}


onSubmit() {
  if (this.fileUpload._files.length > 0) {
  this.slidesService.uploadImage(this.fileUpload._files[0]).subscribe((response: ImageInterface) => {
    if (response.id && response.url) {
      this.slidesService.createSlide({
        title: this.title,
        description: this.description,
        image: response.id
      }).subscribe((response) => {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Diapositive créée avec succès' });
        this.display = false;
        this.loadSlides();
        this.fileUpload.clear();
        this.title = '';
        this.description = '';
      });
      }
    });
  } else {
    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Veuillez uploader une image' });
  }
}

deleteSlide(id: string) {
  this.slidesService.deleteSlide(id).subscribe((response) => {
    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Diapositive supprimée avec succès' });
    this.loadSlides();
  });
}

confirmDelete(event: Event, id: string) {
  this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Voulez-vous supprimer cette diapositive?',
      header: 'Confirmation de suppression',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass:"p-button-danger p-button-text",
      rejectButtonStyleClass:"p-button-text p-button-text",
      acceptIcon:"pi pi-trash",
      rejectIcon:"pi pi-times",

      accept: () => {
          this.messageService.add({ severity: 'info', summary: 'Supprimé', detail: 'Diapositive supprimée avec succès' });
          this.deleteSlide(id);
      },
      reject: () => {
          this.messageService.add({ severity: 'error', summary: 'Rejeté', detail: 'Vous avez rejeté la suppression' });
      }
  });
}




}
