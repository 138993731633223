
import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { TagModule } from 'primeng/tag';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
interface Status {
  label: string;
  value: string;
}

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  standalone: true,
  imports: [
    TableModule, 
    CommonModule, 
    DropdownModule, 
    FormsModule, 
    PaginatorModule, 
    TagModule, 
    ButtonModule,
    ConfirmDialogModule,
    ToastModule
  ],
  providers: [MessageService, ConfirmationService]
})


export class PaymentsComponent implements OnInit {
  payments: any[] = [];
  totalRecords: number = 0;
  selectedStatus: Status = { label: 'All', value: 'all' }; // To filter by status
  currentPage: number = 1; // To manage pagination
  limit: number = 10; // Default number of records per page
  first = 0;
  rows = 5;

  statuses: Status[] = [
    { label: 'All', value: 'all' },
    { label: 'Failed', value: 'failed' },
    { label: 'Success', value: 'success' },
    { label: 'Pending', value: 'pending' }
  ];
  
  // selectedPaymentId: string;

  constructor(private paymentService: PaymentService, private confirmationService: ConfirmationService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.loadPayments();
  }

  loadPayments(event?: any) {
    const page = event ? event.first / event.rows + 1 : this.currentPage;
    const status = this.selectedStatus;

    // Ensure the limit is passed to the service call
    this.paymentService.getPayments(this.currentPage, this.rows, status.value).subscribe((data: any) => {
      console.log("data", data)
      this.payments = data.results;
      this.totalRecords = data.total;
      this.currentPage = data.page;
    });
  }

  onStatusChange(event: any) {
    this.currentPage = 1;
    this.loadPayments();

    console.log("evenety", this.selectedStatus)
  }

  onPageChange(event: any) {
    console.log("event", event)
    this.first = event.first;
    this.rows = event.rows;
    this.currentPage = event.page + 1;
    this.loadPayments();
}

getSeverity(status: string): any {
  switch (status) {
      case 'success':
          return 'success';
      case 'pending':
          return 'warning';
      case 'failed':
          return 'danger';
      default:
          return 'info';
  }
}

  validatePayment(paymentId: string) {
    if (paymentId) {
      this.paymentService.validatePayment(paymentId).subscribe(
        (response) => {
          this.updatePaymentStatus(paymentId, "success");// Reload payments after validation
        },
        (error) => {
        
        }
      );
    }
  }

  updatePaymentStatus(paymentId: string, status: string) {
    if (paymentId) {
      this.paymentService.updatePaymentStatus(paymentId, status).subscribe(
        (response) => {
          this.loadPayments(); // Reload payments after validation
          this.messageService.add({ severity: 'info', summary: 'Validé', detail: 'Paiement validé avec succès' });
        },
        (error) => {
        
        }
      );
    }
  }

  confirmPayment(event: Event, id: string) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Voulez-vous valider ce paiement?',
        header: 'Confirmation de validation',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger p-button-text",
        rejectButtonStyleClass:"p-button-text p-button-text",
        acceptIcon:"pi pi-check",
        rejectIcon:"pi pi-times",
  
        accept: () => {
            this.messageService.add({ severity: 'info', summary: 'Validé', detail: 'Paiement validé avec succès' });
            this.validatePayment(id);
        },
        reject: () => {
            this.messageService.add({ severity: 'error', summary: 'Rejeté', detail: 'Vous avez rejeté la suppression' });
        }
    });
  }
}
