import { Component } from '@angular/core';
import { EventsService } from '../services/events.service';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [CommonModule, TableModule, TagModule, ButtonModule, PaginatorModule],
  templateUrl: './events.component.html',
  styleUrl: './events.component.css'
})
export class EventsComponent {

  events: any[] = [];
  totalRecords: number = 0;
  currentPage: number = 1; // To manage pagination
  limit: number = 10; // Default number of records per page
  first = 0;
  rows = 5;
  eventTypes: any[] = [
    { label: 'Vote', value: 'vote' },
    { label: 'Ticketing', value: 'ticketing' }
  ];

  selectedEventType: any = this.eventTypes[0];


  
  // selectedPaymentId: string;

  constructor(private eventsService: EventsService) {}

    ngOnInit(): void {
      this.loadEvents();
  }

  loadEvents(event?: any) {
    const page = event ? event.first / event.rows + 1 : this.currentPage;

    // Ensure the limit is passed to the service call
    this.eventsService.getEvents(this.currentPage, this.rows, this.selectedEventType.value).subscribe((data: any) => {
      console.log("data", data)
      this.events = data.results;
      this.totalRecords = data.total;
      this.currentPage = data.page;
    });
  }

  updateEvent(id: string, data: any) {
    this.eventsService.updateEvent(id, data).subscribe((data: any) => {
      console.log("data", data)
      this.loadEvents();
    });
    
  }
 

  onEventTypeChange(event: any) {
    this.currentPage = 1;
    this.loadEvents(event);
  }

  onPageChange(event: any) {
    console.log("event", event)
    this.first = event.first;
    this.rows = event.rows;
    this.currentPage = event.page + 1;
    this.loadEvents();
}

getSeverity(status: string): any {
  switch (status) {
      case 'draft':
          return 'success';
      case 'published':
          return 'warning';
      case 'archived':
          return 'danger';
      default:
          return 'info';
  }
}

}
