import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private apiUrl = `${environment.apiUrl}/payments`;

  constructor(private http: HttpClient) {}

  getPayments(page: number = 1, limit: number = 5, status:string): Observable<any> {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found');
    }

    return this.http.get(`${this.apiUrl}/all?page=${page}&limit=${limit}&status=${status}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  // getPayments(page: number, limit: any): Observable<any> {
  //   const token = localStorage.getItem('token');
  //   if (!token) {
  //     // Handle missing token (e.g., redirect to login)
  //    console.log("pade token mon ga")
  //   }
    
  //   return this.http.get(`${this.apiUrl}/all?page=1&limit=5&status=all`, {
  //     headers: {
  //       'Authorization': `Bearer ${token}`
  //     }
  //   });
  // }

  validatePayment(paymentId: string): Observable<any> {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found');
    }

    return this.http.post(`${this.apiUrl}/validate/withdrawal`, { paymentId }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
  }

  updatePaymentStatus(paymentRef: string, status: string): Observable<any> {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found');
    }

    return this.http.patch(`${this.apiUrl}/update/withdrawal/status`, { 
      status, 
      reference: paymentRef 
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
  }
}

