import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api'; 
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, InputTextModule, ButtonModule,ToastModule, ButtonModule, RippleModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent {
  username = '';
  password = '';

  constructor(private authService: AuthService, private router: Router,  private messageService: MessageService) {}
  show() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message Content' });
}
  onLogin() {
    this.authService.login(this.username, this.password).subscribe(
      (response: any) => {
        // console.log("access_token", response)
        localStorage.setItem('token', response.access_token);
       
        this.messageService.add({severity:'success', summary:'Success', detail:'Login successful!'})
        this.router.navigate(['/dashboard']);
      },
      (error) => {
        // Handle error (e.g., show a notification)
        this.messageService.add({severity:'error', summary:'Error', detail:'Login failed!'})
      
      }
    );
  }
}
