<div style="margin-top: 20px">
  <p-toast />
  <div class="card flex h-full">
    <p-dialog
      header="Ajouter un utilisateur"
      [modal]="true"
      [(visible)]="display"
      [style]="{ width: '25rem', 'height': '80vh' }"
    >
      <form
        [formGroup]="userForm"
        style="padding: 10px"
        (ngSubmit)="onSubmitUserCreation()"
      >
        <div class="flex justify-content-between align-items-center gap-3 mb-3">
          <label for="title" class="font-semibold w-6rem">Nom</label>
          <input
            [className]="'input-style'"
            type="text"
            pInputText
            id="title"
            class="flex-auto"
            autocomplete="on"
            formControlName="lastName"
          />
        </div>
        <div class="flex justify-content-between align-items-center gap-3 mb-5">
          <label for="firstName" class="font-semibold w-6rem">Prénom</label>
          <input
            [className]="'input-style'"
            type="text"
            pInputText
            id="firstName"
            class="flex-auto"
            autocomplete="on"
            formControlName="firstName"
          />
        </div>
        <div class="flex justify-content-between align-items-center gap-3 mb-5">
          <label for="email" class="font-semibold w-6rem">Email</label>
          <input
            [className]="'input-style'"
            type="email"
            pInputText
            id="email"
            class="flex-auto"
            autocomplete="on"
            formControlName="email"
          />
        </div>
        <div class="flex justify-content-between align-items-center gap-3 mb-5">
          <label for="phoneNumber" class="font-semibold w-6rem"
            >Téléphone</label
          >
          <p-inputNumber
            id="phoneNumber"
            class="flex-auto"
            autocomplete="on"
            formControlName="phoneNumber"
          />
        </div>
        <div class="flex justify-content-between align-items-center gap-3 mb-5">
          <label for="role" class="font-semibold w-6rem">Rôle</label>
          <p-dropdown
            id="role"
            formControlName="role"
            [options]="roles"
            optionLabel="label"
            placeholder="Select Role"
            [style]="{ 'margin-bottom': '20px' }"
            [virtualScroll]="true"
            [virtualScrollItemSize]="38"
          >
          </p-dropdown>
        </div>

        <div class="flex justify-content-end gap-2">
          <p-button
            label="Annuler"
            severity="secondary"
            (onClick)="display = false"
          />
          <p-button label="Enregistrer" type="submit" />
        </div>
      </form>
    </p-dialog>
  </div>

  <div class="flex justify-content-between">
    <div class="flex gap-5 items-center justify-content-center">
      <div class="flex gap-2 flex-column" >
        <label>Rôle</label>
        <p-dropdown
          [(ngModel)]="role"
          (ngModelChange)="onRoleChange($event)"
          [options]="roles"
          optionLabel="label"
          placeholder="Select Role"
          [style]="{ 'margin-bottom': '20px' }"
        >
        </p-dropdown>
      </div>

      <div class="flex gap-2 flex-column">
        <label>Statut</label>
        <p-dropdown
          [(ngModel)]="status"
          (ngModelChange)="onStatusChange($event)"
          [options]="statuses"
          optionLabel="label"
          placeholder="Select Status"
          [style]="{ 'margin-bottom': '20px' }"
        >
        </p-dropdown>
      </div>
    </div>

    <p-button
      (onClick)="showDialog()"
      icon="pi pi-plus"
      label="Ajouter un utilisateur"
    />
  </div>

  <p-table
    [value]="users"
    [paginator]="false"
    [rows]="rows"
    [totalRecords]="totalRecords"
    (onLazyLoad)="loadUsers($event)"
    responsiveLayout="stack"
    [breakpoint]="'960px'"
    [tableStyle]="{ 'min-width': '50rem' }"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Nom</th>
        <th>Prénom</th>
        <th>Email</th>
        <th>Rôle</th>
        <th>Numéro</th>
        <th>Statut</th>
        <th>Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr>
        <td>{{ user.lastName }}</td>
        <td>{{ user.firstName }}</td>
        <td>{{ user.email }}</td>
        <td>
          <p-tag
            [value]="user.role | titlecase"
            [severity]="getSeverity(user.role)"
          ></p-tag>
        </td>
        <td>{{ user.phoneNumber }}</td>
        <td>
          <p-tag
            [value]="user.active ? 'Actif' : 'Archivé'"
            [severity]="user.active ? 'success' : 'danger'"
          ></p-tag>
        </td>
        <td class="flex gap-2">
          <p-confirmDialog />
          <p-button
            icon="pi pi-user-edit"
            [outlined]="true"
            severity="success"
            (onClick)="showEditDialog(user)"
          />
          <p-button
            icon="pi pi-trash"
            [outlined]="true"
            severity="danger"
            (onClick)="confirmDelete($event, user.id)"
          />
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="flex-auto">
    <p-paginator
      (onPageChange)="onPageChange($event)"
      [first]="first"
      [rows]="rows"
      [totalRecords]="totalRecords"
      [rowsPerPageOptions]="[5, 10, 20, 30]"
    />
  </div>
  <div class="card flex">
    <p-dialog
      header="Modifier un utilisateur"
      [modal]="true"
      [(visible)]="displayEdit"
      [style]="{ width: '25rem', 'height': '80vh'  }"
    >
      <form
        [formGroup]="userEditForm"
        style="padding: 10px"
        (ngSubmit)="onSubmitUserEdit()"
      >
        <div class="flex justify-content-between align-items-center gap-3 mb-3">
          <label for="title" class="font-semibold w-6rem">Nom</label>
          <input
            [className]="'input-style'"
            type="text"
            pInputText
            id="title"
            class="flex-auto"
            autocomplete="on"
            formControlName="lastName"
          />
        </div>
        <div class="flex justify-content-between align-items-center gap-3 mb-5">
          <label for="firstName" class="font-semibold w-6rem">Prénom</label>
          <input
            [className]="'input-style'"
            type="text"
            pInputText
            id="firstName"
            class="flex-auto"
            autocomplete="on"
            formControlName="firstName"
          />
        </div>
        <div class="flex justify-content-between align-items-center gap-3 mb-5">
          <label for="email" class="font-semibold w-6rem">Email</label>
          <input
            [className]="'input-style'"
            type="email"
            pInputText
            id="email"
            class="flex-auto"
            autocomplete="on"
            formControlName="email"
          />
        </div>
        <div class="flex justify-content-between align-items-center gap-3 mb-5">
          <label for="phoneNumber" class="font-semibold w-6rem"
            >Téléphone</label
          >
          <p-inputNumber
            id="phoneNumber"
            class="flex-auto"
            autocomplete="on"
            formControlName="phoneNumber"
          />
        </div>
        <div class="flex justify-content-between align-items-center gap-3 mb-5">
          <label for="phoneNumber" class="font-semibold w-6rem">Rôle</label>
          <p-dropdown
            formControlName="role"
            [options]="roles"
            optionLabel="label"
            placeholder="Select Role"
            [style]="{ 'margin-bottom': '20px' }"
          >
          </p-dropdown>
        </div>

        <div class="flex justify-content-end gap-2">
          <p-button
            label="Annuler"
            severity="secondary"
            (onClick)="display = false"
          />
          <p-button label="Modifier" type="submit" />
        </div>
      </form>
    </p-dialog>
  </div>
</div>
