import { Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersComponent } from './users/users.component';
import { PaymentsComponent } from './payments/payments.component';
import { LayoutComponent } from './layout.component';
import { SlidesComponent } from './slides/slides.component';
import { EventsComponent } from './events/events.component';
import { TicketingComponent } from './ticketing/ticketing.component';

export const appRoutes: Routes = [
    { path: '', component: LoginComponent },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'users', component: UsersComponent },
      { path: 'payments', component: PaymentsComponent },
      { path: 'slides', component: SlidesComponent },
      { path: 'events', component: EventsComponent },
      { path: 'ticketing', component: TicketingComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
    ],
  },
  { path: '**', redirectTo: '' },
  // { path: '', component: LoginComponent },
  // { 
  //   path: 'admin',
  //   component: LayoutComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     { path: 'dashboard', component: DashboardComponent },
  //     { path: 'users', component: UsersComponent },
  //     { path: 'payments', component: PaymentsComponent },
  //     { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
  //   ]
  // },
  // { path: '**', redirectTo: '' } // Handle unknown routes
];

