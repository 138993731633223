// import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';

// @Injectable({
//   providedIn: 'root' // This ensures the service is provided at the root level
// })
// export class AuthService {
//   private apiUrl = 'https://back-preprod.tonvootee.com/auth/login';

//   constructor(private http: HttpClient) {}

//   login(username: string, password: string): Observable<any> {
//     const body = { username, password };
//     return this.http.post(this.apiUrl, body, {
//       headers: {
//         'Content-Type': 'application/json',
//       }
//     });
//   }
// }


// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthService {

//   constructor() { }
// }


// import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthService {
//   private apiUrl = 'https://back-preprod.tonvootee.com/auth/login';

//   constructor(private http: HttpClient) {}

//   login(username: string, password: string): Observable<any> {
//     const body = { username, password };
//     return this.http.post(this.apiUrl, body, {
//       headers: {
//         'Content-Type': 'application/json',
//       }
//     });
//   }
// }


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = `${environment.apiUrl}/auth/login`;

  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<any> {
    const body = { username, password };
    return this.http.post(this.apiUrl, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }
}