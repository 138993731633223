import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarModule } from 'primeng/sidebar'; 
import { ButtonModule } from 'primeng/button'; 
import { RouterModule } from '@angular/router';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  standalone: true,
  imports: [SidebarModule, ButtonModule, RouterModule, MenuModule, NgFor],
  styleUrls: ['../assets/css/layout.css']
})
export class LayoutComponent implements OnInit {
  visibleSidebar: boolean = false;
  items: MenuItem[] = [];
  constructor(private router: Router) {}
  ngOnInit(): void {
    this.items = [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        routerLink: '/dashboard'
      },
      {
        label: 'Events',
        icon: 'pi pi-fw pi-calendar',
        routerLink: '/events'
      },
      
      {
        label: 'Payments',
        icon: 'pi pi-fw pi-credit-card',
        routerLink: '/payments'
      },
      {
        label: 'Users',
        icon: 'pi pi-fw pi-users',
        routerLink: '/users'
      },
      {
        label: 'Slides',
        icon: 'pi pi-fw pi-image',
        routerLink: '/slides'
      }
    ];
  }

  isActive(item: MenuItem): boolean {
    return this.router.isActive(item.routerLink, true);
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }
}
