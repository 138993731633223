<div style="margin-top: 20px">
  <p-dropdown
  [(ngModel)]="selectedStatus"
  (ngModelChange)="onStatusChange($event)"
  [options]="statuses"
  optionLabel="label"
  placeholder="Select Status"
  [style]="{'margin-bottom': '20px'}"
  >
</p-dropdown>

<p-table
  [value]="payments"
  [paginator]="false"
  [rows]="rows"
  [totalRecords]="totalRecords"
  (onLazyLoad)="loadPayments($event)"
  responsiveLayout="stack" 
  [breakpoint]="'960px'" 
  [tableStyle]="{'min-width': '50rem'}"
  >
  <ng-template pTemplate="header">
    <tr>
      <th>Montant</th>
      <th>Statut</th>
      <th>Référence</th>
      <th>Type</th>
      <th>Méthode</th>
      <th>Service</th>
      <th>Numéro</th>
      <th>Créé le</th>
      <th>Action</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-payment>
    <tr>
      <td>{{ payment.amount | currency:'XOF' }}</td>
      <td> <p-tag [value]="payment.status | titlecase" [severity]="getSeverity(payment.status)"></p-tag></td>
      <td>{{ payment.reference }}</td>
      <td>{{ payment.type }}</td>
      <td>{{ payment.method }}</td>
      <td>{{ payment.service }}</td>
      <td>{{ payment.number }}</td>
      <td>{{ payment.createdAt | date: 'fullDate' }}</td>
      <td>
        <p-confirmDialog />
        <p-button *ngIf="payment.reference.startsWith('PAY-OUT-') && !payment.method.startsWith('internal') && payment.status === 'pending'" [disabled]="payment.status === 'success' || payment.status === 'failed'" icon="pi pi-check" [outlined]="true" (onClick)="confirmPayment($event, payment.reference)" severity="success" />
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="flex-auto">
  <p-paginator 
      (onPageChange)="onPageChange($event)" 
      [first]="first" 
      [rows]="rows" 
      [totalRecords]="totalRecords" 
      [rowsPerPageOptions]="[5, 10, 20, 30]"
       />
</div>
<!-- 
<p-dropdown
  [(ngModel)]="selectedPaymentId"
  [options]="payments"
  optionLabel="id"
  placeholder="Select a Payment">
</p-dropdown>

<button pButton type="button" label="Validate Payment" (click)="validatePayment(selectedPaymentId)">
  Validate Payment
</button> -->

</div>