<div style="margin-top: 20px">

    <p-dropdown
  [(ngModel)]="selectedEventType"
  (ngModelChange)="onEventTypeChange($event)"
  [options]="eventTypes"
  optionLabel="label"
  placeholder="Select Event Type"
  [style]="{'margin-bottom': '20px'}"
  >
</p-dropdown>
  
  <p-table
    [value]="events"
    [paginator]="false"
    [rows]="rows"
    [totalRecords]="totalRecords"
    (onLazyLoad)="loadEvents($event)"
    responsiveLayout="stack" 
    [breakpoint]="'960px'" 
    [tableStyle]="{'min-width': '50rem'}"
    >
    <ng-template pTemplate="header">
      <tr>
        <th>Image</th>
        <th>Nom</th>
        <!-- <th>Description</th> -->
        <th>Statut</th>
        <th>Prix</th>
        <th>Date de début</th>
        <th *ngIf="selectedEventType.value !== 'ticketing'">Date de fin</th>
        <th>Créé par</th>
        <th>Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-event>
      <tr>
        <td><img [src]="event.image.url" alt="Image" style="width: 100px; height: 100px;"></td>
        <td>{{ event.name }}</td>
        <!-- <td>{{ event.description }}</td> -->
        <td> <p-tag [value]="event.status | titlecase" [severity]="getSeverity(event.status)"></p-tag></td>
        <td>{{ event.price | currency:'XOF' }}</td>
        <td>{{ event.startDate | date:'dd/MM/yyyy HH:mm' }}</td>
        <td *ngIf="event.eventType !== 'ticketing'">{{ event.endDate | date:'dd/MM/yyyy HH:mm' }}</td>
        <td>{{ event.createdBy.firstName }} {{ event.createdBy.lastName }}</td>
        <td >
          <p-button *ngIf="event.status === 'draft'" [outlined]="true" severity="success" label="Publier" (onClick)="updateEvent(event.id, { status: 'published' })" />
          <p-button *ngIf="event.status === 'published'" class="ml-10"  [outlined]="true" severity="danger" label="Retirer" (onClick)="updateEvent(event.id, { status: 'draft' })" />
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="flex-auto">
    <p-paginator 
        (onPageChange)="onPageChange($event)" 
        [first]="first" 
        [rows]="rows" 
        [totalRecords]="totalRecords" 
        [rowsPerPageOptions]="[5, 10, 20, 30]"
         />
  </div>
  <!-- 
  <p-dropdown
    [(ngModel)]="selectedPaymentId"
    [options]="payments"
    optionLabel="id"
    placeholder="Select a Payment">
  </p-dropdown>
  
  <button pButton type="button" label="Validate Payment" (click)="validatePayment(selectedPaymentId)">
    Validate Payment
  </button> -->
  
  </div>