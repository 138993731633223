import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SlidesInterface } from '../models/slides.interface';

@Injectable({
  providedIn: 'root'
})
export class SlidesService {
  private apiUrl = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  getSlides(page: number = 1, limit: number = 5): Observable<any> {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found');
    }
    return this.http.get(`${this.apiUrl}/images/slides?page=${page}&limit=${limit}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  createSlide(data: SlidesInterface): Observable<any> {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found');
    }
    return this.http.post(`${this.apiUrl}/images/slide`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  uploadImage(image: File): Observable<any> {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found');
    }
    const formData = new FormData();
    formData.append('image', image);
    return this.http.post(`${this.apiUrl}/images/upload`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  deleteSlide(id: string): Observable<any> {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found');
    }
    return this.http.delete(`${this.apiUrl}/images/slide/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
}
